import React from 'react';

import Layout from '../components/layout';

const SecondPage = () => (
	<Layout>
		<p className="mensaje-enviado">Muchas gracias, su mensaje ha sido enviado.</p>
	</Layout>
);

export default SecondPage;
